<template>
  <div
    class="page"
    id="cs-privacy-policy-the-third-page"
    :class="{
      'more-depth':
        ($route.meta && $route.meta.headerType === 'more-depth') || $route.params.headerType === 'more-depth',
    }"
  >
    <div class="container">
      <div class="page-header">
        <div class="title">{{ $__t('개인정보 제3자 제공') }}</div>
      </div>

      <div class="page-body">
        <div class="table-area">
          <div class="table">
            <div class="table-row">
              <div class="table-cell bg-gray">
                {{ $__t('제공받는자') | capitalizeFirstLetter }}
              </div>
              <div class="table-cell">
                {{ receipient }}
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell bg-gray">
                {{ $__t('제공 목적') | capitalizeFirstLetter }}
              </div>
              <div class="table-cell">
                <strong>{{ $__t('예약·서비스의 제공 및 계약의 이행') }}</strong
                ><br />
                {{ $__t('(이용자 및 이용정보 확인), 민원처리 등 소비자 분쟁해결') }}
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell bg-gray">
                {{ $__t('제공 항목') | capitalizeFirstLetter }}
              </div>
              <div class="table-cell">
                {{ $__t('예약자 정보(예약자명, 휴대폰 번호, 이메일 주소)') }}
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell bg-gray">
                {{ $__t('이용 및 보유기간') | capitalizeFirstLetter }}
              </div>
              <div class="table-cell">
                <strong>{{ $__t('개인정보 이용목적 달성 시까지') }}</strong
                ><br />
                {{ $__t('단, 관계법령에 의하여 보존할 필요성이 있는 경우 그 시점까지 보존 후 지체 없이 파기') }}
              </div>
            </div>
          </div>
          <div class="table-description">
            {{ $__t('※ 위 개인정보 제3자 제공 동의를 거부할 수 있으며, 거부할 경우 서비스 이용이 제한됩니다.') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      receipient: this.$route.params.store_name || '',
    };
  },

  mounted() {
    setTimeout(() => {
      this.toggleHeaderTitleVisibility();
      window.addEventListener('scroll', this.toggleHeaderTitleVisibility);
    }, 0);
  },

  beforeDestroy() {
    this.toggleHeaderTitleVisibility(true);
    window.removeEventListener('scroll', this.toggleHeaderTitleVisibility);
  },

  methods: {
    toggleHeaderTitleVisibility(clear, e) {
      const $pageHeaderTitle = $('.page-header > .title', this.$el);
      const $siteHeaderTitle = $('#site-header > .container > .header > .title');
      const $siteHeader = $('#site-header');

      if (clear === true) {
        $siteHeaderTitle.removeClass('off');
        return;
      }

      const isHeaderCovered = $pageHeaderTitle.offset().top - $pageHeaderTitle.outerHeight() < window.scrollY;

      if (!isHeaderCovered) {
        $siteHeaderTitle.addClass('off');
        $siteHeader.removeClass('shadow');
      } else {
        $siteHeaderTitle.removeClass('off');
        $siteHeader.addClass('shadow');
      }
    },
  },
};
</script>
